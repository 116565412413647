<template>
  <v-container>
    <v-layout align-center>
      <v-btn
        color="primary"
        icon
        @click="decrement"
        :disabled="value <= initialMin"
      >
        <v-icon>$minus-circle</v-icon>
      </v-btn>
      {{ value }}
      <v-btn
        color="primary"
        icon
        @click="increment"
        :disabled="value >= initialMax"
      >
        <v-icon>$plus-circle</v-icon>
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    }
  },
  data: () => ({
    value: 0,
    initialMin: 0,
    initialMax: 10
  }),
  watch: {
    value (val) {
      this.$emit('input', val)
    }
  },
  created () {
    this.initialMin = this.min
    this.initialMax = this.max
  },
  methods: {
    increment () {
      if (this.value < this.initialMax) {
        this.value++
        this.$emit('increment', this.value)
      }
    },
    decrement () {
      if (this.value > this.initialMin) {
        this.value--
        this.$emit('decrement', this.value)
      }
    },
    decrementSilently () {
      if (this.value > this.initialMin) {
        this.value--
      }
    }
  }
}
</script>
