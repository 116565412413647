export default function (color: string, amount: number): string {
  let usePound = false

  if (color[0] === '#') {
    color = color.slice(1)
    usePound = true
  }

  const num = parseInt(color, 16)

  let r = (num >> 16) + amount * 255
  let b = ((num >> 8) & 0x00FF) + amount * 255
  let g = (num & 0x0000FF) + amount * 255

  r = r < 255 ? r : 255
  b = b < 255 ? b : 255
  g = g < 255 ? g : 255

  r = r > 0 ? r : 0
  b = b > 0 ? b : 0
  g = g > 0 ? g : 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')
}
