











































































































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import SelectableEmployee from '@/calendesk/models/SelectableEmployee'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import VCardWrapper from '@/calendesk/sections/section/calendars/calendarV2/components/VCardWrapper.vue'

export default mixins(DraftElement).extend({
  components: { VCardWrapper },
  props: {
    selectableEmployee: {
      type: SelectableEmployee,
      required: true
    },
    textColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    changeMode: {
      type: Boolean,
      default: false
    },
    disableChanging: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    classForViewRound: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      truncateDescription: true
    }
  },
  computed: {
    getEmployeesToDisplay (): Employee[] {
      return this.selectableEmployee.employees.slice(0, this.$vuetify.breakpoint.mdAndUp ? 5 : 3)
    },
    socialMedia (): Record<string, any>[] {
      // Create an array with all possible social media entries
      const allEntries = [
        {
          icon: '$fb',
          link: this.selectableEmployee.getFacebookUrl()
        },
        {
          icon: '$instagram',
          link: this.selectableEmployee.getInstagramUrl()
        },
        {
          icon: '$yt',
          link: this.selectableEmployee.getYoutubeUrl()
        },
        {
          icon: '$twitter',
          link: this.selectableEmployee.getTwitterUrl()
        },
        {
          icon: '$linkedin',
          link: this.selectableEmployee.getLinkedInUrl()
        }
      ]

      // Filter the array to include only those entries where the link exists
      return allEntries.filter(entry => entry.link)
    }
  },
  methods: {
    update () {
      this.$emit('change', this.changeMode ? null : this.selectableEmployee)
    }
  }
})
