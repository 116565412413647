

























import Vue from 'vue'
import adjustColor from '@/calendesk/filters/adjustColor'

export default Vue.extend({
  props: {
    textColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    changeMode: {
      type: Boolean,
      default: false
    },
    clickFunction: {
      type: Function,
      default: null
    },
    classForViewRound: {
      type: String,
      default: ''
    }
  },
  computed: {
    getColor (): string {
      if (this.changeMode) {
        return adjustColor(this.color, this.$vuetify.theme.dark ? 0.05 : -0.05)
      }

      return this.color
    }
  }
})
